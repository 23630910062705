import React from 'react'
import './Item.css'
import { Link } from 'react-router-dom'

const Item = (props) => {
  return (
    <div className='item'>
      <Link to={`/product/${props.id}`}><div className='item-box'>
        <img onClick={window.scrollTo(0,0)} src={props.image} alt='' />
        <div className='item-text'>
          <div>
            <p className='name'>{props.name} </p>
            <p className='size'>({props.size}) - {props.color}</p>
          </div>
          <div className="item-prices">
              <div className="item-price-new">
                  ${props.new_price}
              </div>
              <div className="item-price-old">
                  {props.old_price}
              </div>
          </div>
        </div>
        <div className='item-btn'>
          View Product
        </div>
      </div></Link>
    </div>
  )
}

export default Item